import { useEffect, useState } from 'react'
import { Select } from 'antd'
import { Controller, useFormContext } from 'react-hook-form'
import ErrorItem from '@/components/AddressView/ErrorItem'
import { Apartment } from '@/types/register'
import { selectRegister, setScheduleData } from '@/features/register/registerSlice'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { getApartments } from '@/services/app.service'

export const mapOptions = (apartments: Apartment[]) => {
	
	const options = apartments.map((apartment: Apartment) => {
		return {
			value: apartment.id,
			label: apartment.name,
		}
	})

	return options
}

export type Props = {}

const ApartmentSelect = () => {
	const [status, setStatus] = useState<string>('idle')
	const [apartments, setApartments] = useState<Apartment[]>([])

	const dispatch = useAppDispatch()
	const register = useAppSelector(selectRegister)

	const {
		control,
		formState: { errors },
	} = useFormContext()

	useEffect(() => {
		// Using an IIFE
		;(async () => {
			setStatus('loading')
			try {
				const apartments = await getApartments()
				setApartments(apartments)
				setStatus('fulfilled')
			} catch (error: any) {
				setStatus('error')
			}
		})()
	}, [])

	// Handlers
	const handleApartmentChange = (value: number) => {
		dispatch(setScheduleData({ apartment: value }))
	}



	return (
		<div className="apartment-select form-item mb-4">
			<Controller
				name="apartment"
				control={control}							
				render={({ field: { onChange } }) => {
					return (
						<Select
							loading={status === 'loading'}
							value={register.payload.schedule.apartment}
							placeholder="*Selecciona un tipo de vivienda"
							className="grf-select text-center"
							options={mapOptions(apartments)}
							onChange={(value) => {
								onChange(value) // for validation
								handleApartmentChange(value) // expose event
							}}
						/>
					)
				}}
			/>
			<ErrorItem message={errors.apartment?.message} />
		</div>
	)
}

export default ApartmentSelect
