import { Input, Select, message } from 'antd'
import { Controller, useFormContext } from 'react-hook-form'
import LabelItem from '@/components/AddressView/LabelItem'
import ErrorItem from '@/components/AddressView/ErrorItem'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { setAddressDataKey } from '@/features/register/registerSlice'
import { selectRegister, setScheduleData } from '@/features/register/registerSlice'
import ApartmentSelect from '@/components/AddressView/ApartmentSelect'
import dynamic from 'next/dynamic'
import type { AddressType, State, AddressZip, Colony } from '@/types/register'
import type { GeoLocationType } from '@/components/AddressView/GoogleMapView'
import { DEFAULT_LOCATION, LOCATION_CUSTOMER } from '@/modules/Register/config/contants'
import Geocode from 'react-geocode'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'

import {
	resetAllData,
	setAddressData,
	setAvailability,
	setCustomerDataKey,
	setGeoLocation,
} from '@/features/register/registerSlice'
import { getAddressComponents, getAddressFrom } from '@/components/AddressView/GoogleMapView'
import { useCallback, useEffect, useState } from 'react'
import { getStates, getStateZipCodes, getColony } from '@/services/app.service'
import AsyncSelect from 'react-select/async'
import { PlaceOption } from '../SearchAsync/Options'
import { add } from 'lodash'
const GOOGLE_API_KEY: string = process.env['NEXT_PUBLIC_GOOGLE_API_KEY'] || ''

export const getStateValue = (key: string, value: string) => {
	return {
		key,
		value,
	}
}

const GoogleMapView = dynamic(() => import('./GoogleMapView'), {
	ssr: false,
})

export const mapOptions = (states: State[]) => {
	const options = states.map((states: State) => {
		return {
			value: states.estado,
			label: states.estado,
		}
	})

	return options
}
export const mapOptionsStateZipCodes = (addressZip: AddressZip[]) => {
	const options = addressZip.map((addressZip: AddressZip) => {
		return {
			value: addressZip.zip_code,
			label: addressZip.zip_code,
		}
	})

	return options
}

export const mapOptionsColony = (colony: Colony[]) => {
	const options = colony.map((colony: Colony) => {
		return {
			value: colony.neighborhood,
			label: colony.neighborhood,
		}
	})

	return options
}

const AddressForm = (): JSX.Element => {
	const dispatch = useAppDispatch()

	const register = useAppSelector(selectRegister)
	const { address } = register.payload
	const [status, setStatus] = useState<string>('idle')
	const [states, setStates] = useState<State[]>([])
	const [zipCodes, setZipCodes] = useState<AddressZip[]>([])
	const [colony, setColony] = useState<Colony[]>([])
	const [currentLocation, setCurrentLocation] = useState<GeoLocationType>(register.payload.location)
	const { placePredictions, getPlacePredictions, isPlacePredictionsLoading, placesService } = usePlacesService({
		apiKey: GOOGLE_API_KEY,
		debounce: 100,
		libraries: ['places', 'geometry'], // 'geometry'
	})
	const { apartment } = register.payload.schedule
	const loadOptions = (newValue: string, callback: (options: AddressZip[]) => void) => {
		let inputValue = newValue
		let prefix = 'CDMX, '

		if (inputValue.indexOf(prefix) == 0) {
			//- console.log(newValue)
		} else {
			if (prefix.indexOf(inputValue) >= 0) {
				inputValue = prefix
			} else {
				inputValue = inputValue
			}
		}

		const options = zipCodes
			.map((addressZip: AddressZip) => {
				return {
					value: addressZip.zip_code,
					label: addressZip.zip_code,
				}
			})
			.filter((addressZip) => addressZip.label.startsWith(inputValue))

		callback(options)
	}

	const loadOptions_ = (newValue: string, callback: (options: Colony[]) => void) => {
		let inputValue = newValue
		let prefix = 'CDMX, '

		if (inputValue.indexOf(prefix) == 0) {
			//- console.log(newValue)
		} else {
			if (prefix.indexOf(inputValue) >= 0) {
				inputValue = prefix
			} else {
				inputValue = inputValue
			}
		}

		const options = colony
			.map((addressZip: Colony) => {
				return {
					value: addressZip.neighborhood,
					label: addressZip.neighborhood,
				}
			})
			.filter((addressZip) => addressZip.label.toLowerCase().startsWith(inputValue))
		callback(options)
	}

	// Global hook form
	const { setValue, formState, resetField, setError } = useFormContext()
	const {
		control,
		formState: { errors },
	} = useFormContext()

	const handleInputChange = (key: string, value: any, onChange: (value: any) => void) => {
		const nState = getStateValue(key, value)
		onChange(value)
		dispatch(setAddressDataKey(nState))
	}
	const setHookFormData = (data: {}) => {
		for (let [field, value] of Object.entries(data)) {
			setValue(field, value)
		}
	}

	//get all states, when component is mounted : initial
	useEffect(() => {
		// Using an IIFE
		;(async () => {
			setStatus('loading')
			try {
				const state = await getStates()
				setStates(state)
			} catch (error: any) {
				setStatus('error')
			} finally {
				setStatus('fulfilled')
			}

			return () => {
				// Cancel any subscriptions or cleanup tasks here
			}
		})()
	}, [])

	//get all zip codes , when exist a state,  component  is mounted
	useEffect(() => {
		if (address.state) {
			const getData = async () => {
				setStatus('loading')
				try {
					const ZipCodes = await getStateZipCodes(address.state)
					setZipCodes(ZipCodes)
					// dispatch
				} catch (error: any) {
					setStatus('error')
				}finally {
					setStatus('fulfilled')
				}
			}
			getData()

			return () => {
				// Cleanup function
			}
		}
	}, [address.state])

	//get all colonys  , when exist a zip code ,  component  is mounted
	useEffect(() => {
		if (address.zip_code) {
			const getData = async () => {
				setStatus('loading')
				try {
					const colonyData = await getColony(address.zip_code)
					setColony(colonyData)
					setStatus('fulfilled')
				} catch (error: any) {
					setStatus('error')
				}
			}
			getData()

			return () => {
				// Cleanup function
			}
		}
	}, [address.colony, address.zip_code])

	useEffect(() => {
		if (address.colony) {
			const getData = async () => {
				setStatus('loading')
				try {
					const alcaldia = colony
						.map((colony) => colony)
						.filter((colony) => colony.neighborhood === address.colony)[0]
					const nState = getStateValue('country', alcaldia.alcaldia)
					dispatch(setAddressDataKey(nState))
				} catch (error: any) {
					setStatus('error')
				}finally {
					setStatus('fulfilled')
				}
			}
			getData()

			return () => {
				// Cleanup function
			}
		}
	}, [address.colony, colony, dispatch])

	useEffect(() => {
		// if ((!address.state && !address.zip_code ) || !address.state || !address.street) return
		setStatus('loading')
		const address_ = `${address.street} ${address.ext_number}  ${address.colony}  ${address.state} ${address.zip_code}`
		Geocode.fromAddress(address_)
			.then((response) => {
				const data = response.results[0].geometry.location
				dispatch(setGeoLocation(data))
				setStatus('fulfilled')
			})
			.catch((error) => {
				console.log(error)
				setStatus('error')
			})
		return () => {}
	}, [address, dispatch])

	const handleGeoCodeDragEnd = async (data: AddressType, location: GeoLocationType) => {
		try {
			// const nState = {
			// 	...data,
			// }
			// // React hook form state
			// setHookFormData(nState)

			// // Send to Global state
			// dispatch(setAddressData(nState))
			dispatch(setGeoLocation(location))
		} catch (error: any) {
			if (error?.message) {
				message.warn(error.message, 5)
			} else {
				message.warn('No se pudo obtener la dirección.')
			}
		}
	}
	// Handlers
	const handleStatesChange = (key: string, value: any, onChange: (value: any) => void) => {
		if (address.state) {
			const _nState = getStateValue('zip_code', '')
			onChange('')
			dispatch(setAddressDataKey(_nState))
			const nState = getStateValue('colony', '')
			onChange('')
			dispatch(setAddressDataKey(nState))
			const nState_ = getStateValue('country', '')
			dispatch(setAddressDataKey(nState_))
		}
		const nState = getStateValue(key, value)
		onChange(value)
		dispatch(setAddressDataKey(nState))
	}

	const handleChange = async (key: string, event: any, onChange: (value: any) => void) => {
		if (key === 'zip_code') {
			const nState = getStateValue('colony', '')
			onChange('')
			dispatch(setAddressDataKey(nState))
			const nState_ = getStateValue('country', '')
			dispatch(setAddressDataKey(nState_))
		}
		if (event?.value) {
			const nState = getStateValue(key, event?.value)
			onChange(event?.value)
			dispatch(setAddressDataKey(nState))
		}
	}

	return (
		<div className="address-form mb-4">
			{/*first col */}
			<div className="">
				<div className='grid  grid-cols-1 md:grid-cols-2 "'>
					<div className="flex flex-col md:mr-[2rem] my-auto  ">
						<div className="flex flex-col">
							<div className="grid grid-cols-2 gap-4 mt-4">
								<div className="form-item ">
									<Controller
										name="state"
										control={control}
										render={({ field: { onChange } }) => (
											<Select
												loading={status === 'loading'}
												value={address.state ? address.state : null}
												placeholder="*Selecciona un estado"
												className={`grf-select text-center`}
												options={mapOptions(states)}
												onChange={(event: any) => {
													// onChange() // for validation
													handleStatesChange('state', event, onChange) // expose event
												}}
											/>
										)}
									/>
									<ErrorItem message={errors.state?.message} />
								</div>
								<div className="form-item ">
									<Controller
										name="zip_code"
										control={control}
										render={({ field: { onChange } }) => (
											<AsyncSelect
												// className="grf-input"
												value={
													address.zip_code
														? { value: address.zip_code, label: address.zip_code }
														: null
												}
												size="large"
												autoFocus
												cacheOptions
												isDisabled={!address.state}
												isLoading={isPlacePredictionsLoading}
												defaultOptions={mapOptionsStateZipCodes(zipCodes)}
												className="grf-select text-center"
												loadOptions={loadOptions}
												noOptionsMessage={() => 'Zona sin cobertura'}
												placeholder="*Selecciona un código postal"
												onChange={(event) => handleChange('zip_code', event, onChange)}
											/>
										)}
									/>
									<ErrorItem message={errors.zip_code?.message} />
								</div>
							</div>
							<div className="grid grid-cols-2 gap-4 mt-4">
								<div className="form-item mb-4">
									{/*<LabelItem label="Colonia" required={true} />*/}
									<Controller
										name="colony"
										control={control}
										render={({ field: { onChange } }) => (
											<AsyncSelect
												className="grf-select text-center"
												value={
													address.colony
														? { value: address.colony, label: address.colony }
														: null
												}
												autoFocus
												cacheOptions
												isDisabled={!address.zip_code}
												size="large"
												defaultOptions={mapOptionsColony(colony)}
												loadOptions={loadOptions_}
												placeholder="*Selecciona una colonia"
												noOptionsMessage={() => 'Colonia sin cobertura'}
												onChange={(event) => handleChange('colony', event, onChange)}
											/>
										)}
									/>
									<ErrorItem message={errors.colony?.message} />
								</div>
								<div className="form-item mb-4">
									<Controller
										name="country"
										control={control}
										render={({ field: { onChange } }) => (
											<Input
												readOnly={true}
												disabled={true}
												className="grf-input"
												value={address.country}
												// autoComplete='new-country'
												size="large"
												placeholder="*Ciudad/alcaldia"
												onChange={(event: any) =>
													handleInputChange('country', event.target.value, onChange)
												}
											/>
										)}
									/>
									<ErrorItem message={errors.zip_code?.message} />
								</div>
							</div>
							<div className="form-item mb-4 ">
								{/*<LabelItem label="Calle" required={true} />*/}
								<Controller
									name="street"
									control={control}
									render={({ field: { onChange } }) => (
										<Input
											className="grf-input"
											value={address.street}
											size="large"
											placeholder="*Calle"
											onChange={(event: any) =>
												handleInputChange('street', event.target.value, onChange)
											}
										/>
									)}
								/>
								<ErrorItem message={errors.street?.message} />
							</div>
						</div>
						<div className="grid grid-cols-2 gap-4">
							<div className="form-item mb-4">
								{/*<LabelItem label="Número ext." required={true} />*/}
								<Controller
									name="ext_number"
									control={control}
									render={({ field: { onChange } }) => (
										<Input
											className="grf-input "
											value={address.ext_number}
											size="large"
											placeholder="*No. Exterior"
											onChange={(event: any) =>
												handleInputChange('ext_number', event.target.value, onChange)
											}
										/>
									)}
								/>
								<ErrorItem message={errors.ext_number?.message} />
							</div>
							<div className="form-item mb-4">
								{/*<LabelItem label="Número int." required={false} />*/}
								<Controller
									name="int_number"
									control={control}
									render={({ field: { onChange } }) => (
										<Input
											className="grf-input"
											value={address.int_number}
											size="large"
											placeholder="No. Interior"
											onChange={(event: any) =>
												handleInputChange('int_number', event.target.value, onChange)
											}
										/>
									)}
								/>
								<ErrorItem message={errors.int_number?.message} />
							</div>
						</div>
						<ApartmentSelect />
						{apartment > 7 ? (
							<div className="form-item mb-4">
								{/*<LabelItem label="Nombre del edificio (si aplica)" required={false} />*/}
								<Controller
									name="tower_depot_number"
									control={control}
									render={({ field: { onChange } }) => (
										<Input
											className="grf-input border-2"
											value={address.tower_depot_number}
											size="large"
											placeholder="*Nombre del edificio"
											onChange={(event: any) =>
												handleInputChange('tower_depot_number', event.target.value, onChange)
											}
										/>
									)}
								/>
							</div>
						) : null}

						<LabelItem label="Entre que calles se encuentra tu domicilio" required={false} />

						<div className="grid grid-cols-2 gap-4 ">
							<div className="form-item ">
								<Controller
									name="street_1"
									control={control}
									render={({ field: { onChange } }) => (
										<Input
											className="grf-input"
											value={address.street_1}
											size="large"
											placeholder="Entre calle"
											onChange={(event: any) =>
												handleInputChange('street_1', event.target.value, onChange)
											}
										/>
									)}
								/>
								<ErrorItem message={errors.street_1?.message} />
							</div>
							<div className="form-item ">
								<Controller
									name="street_2"
									control={control}
									render={({ field: { onChange } }) => (
										<Input
											className="grf-input"
											value={address.street_2}
											size="large"
											placeholder="Y calle"
											onChange={(event: any) =>
												handleInputChange('street_2', event.target.value, onChange)
											}
										/>
									)}
								/>
								<ErrorItem message={errors.street_2?.message} />
							</div>
						</div>
						<div className=" gap-4 mt-4">
							<div className="form-item mb-4 ">
								<Controller
									name="reference"
									control={control}
									render={({ field: { onChange } }) => (
										<Input.TextArea
											style={{ minHeight: 60 }}
											rows={4}
											size="large"
											placeholder="Referencias de tu vivienda"
											autoComplete="new-reference"
											onChange={(event: any) =>
												handleInputChange('reference', event.target.value, onChange)
											}
										/>
									)}
								/>
								<ErrorItem message={errors.reference?.message} />
							</div>
						</div>
					</div>
					{/*first col end */}
					<div className="map-container flex flex-col w-full h-full   ">
						<GoogleMapView location={register.payload.location} onGeoCodeDragEnd={handleGeoCodeDragEnd} />
					</div>

					{/*second col  */}
				</div>
				{/*second col end*/}
			</div>
		</div>
	)
}

export default AddressForm
