type FormLabelProps = {
	label: string
	required: boolean
}

const LabelItem = ({ label, required = false }: FormLabelProps) => {
	return (
		<div className="font-medium text-gray-form-500 text-sm  mb-4">
			<span className="text-red-500 mr-1" hidden={!required}>
				*
			</span>
			<strong className="regular text-[#888888] ">{label}</strong>
		</div>
	)
}

export default LabelItem
