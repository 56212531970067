import React, { ReactNode } from 'react'
import { motion } from 'framer-motion'

type Props = {
	children: ReactNode
	title: string
}

const variants = {
	hidden: { opacity: 0.3, x: 0, y: 0 },
	enter: { opacity: 1, x: 0, y: 0 },
	exit: { opacity: 0.3, x: 0, y: 0 },
}

const Layout = ({ children, title }: Props): JSX.Element => (
	<div className={title}>
		<motion.main
			initial="hidden"
			animate="enter"
			exit="exit"
			variants={variants}
			transition={{ type: 'linear' }}
			className="w-full h-full"
		>
			{children}
		</motion.main>
	</div>
)

export default Layout
